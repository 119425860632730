import config from '../plugins/config.js'
import {
    getProductsWithValue,
    getProductsWithValueByTabs,
    getSelectedIds,
    getPlainCommentsArray,
    loadOffer,
    loadOrder,
    loadTab, loadGroupTransports
} from '@/plugins/helpers'
import axios from 'axios'
import router from '../router'
import i18n from '../i18n'
import {encode} from 'url-safe-base64'

export default {
    FETCH_PRICE_LIST({state, commit}) {
        if (state.priceList) {
            return new Promise(((resolve) => {
                resolve(state.priceList)
            }))
        }

        return axios.get('price-list', config.AXIOS_DEFAULTS).then(data => {
            commit('storePriceList', data)
            return data
        })
    },

    //***** AUTH *****//
    FETCH_CURRENT_USER({state, commit}) {
        if (state.userFetched) {
            return new Promise(((resolve, reject) => {
                if (state.authSession || state.authToken) {
                    resolve()
                } else {
                    reject(true)
                }
            }))
        }

        if (!state.authSession && !state.authToken) {
            return Promise.reject(true)
        }

        return axios.get('user', config.AXIOS_DEFAULTS)
            .then(data => {
                commit('storeUserProfile', data)
            }).catch((err) => {
                if (err.response && err.response.status === 503) {
                    const back = encode(btoa(JSON.stringify({
                        name: router.currentRoute.name,
                        params: router.currentRoute.params
                    })))
                    router.replace({name: 'Maintenance', params: {back}})
                    return Promise.reject(false)
                } else if (err.response && err.response.status === 401) {
                    commit('clearUser')
                    return Promise.reject(true)
                }

                return Promise.reject(err)
            })
    },

    VALIDATE_RELOGIN({commit, dispatch}, {payload, remember}) {
        let url = 'auth/relogin'

        return axios.post(url, payload, config.AXIOS_DEFAULTS)
            .then(data => {
                return data
            })
    },

    VALIDATE_LOGIN({commit, dispatch}, {payload, remember}) {
        let url = 'auth/login'

        if (!remember) {
            url = `${url}?session=1`
        }

        return axios.post(url, payload, config.AXIOS_DEFAULTS)
            .then(data => {
                if ('mfa' in data && !data.mfa) {
                    dispatch('STORE_USER', {...data, session: !remember})
                    return false
                } else {
                    commit('storeUserEmail', data.email)
                    commit('storeUserRemember', remember)
                    return true
                }
            })
    },

    VALIDATE_VERIFICATION_CODE({commit, dispatch}, {payload, remember}) {
        let url = 'auth/2fa'

        if (!remember) {
            url = `${url}?session=1`
        }

        return axios.post(url, payload, config.AXIOS_DEFAULTS)
            .then(data => {
                dispatch('STORE_USER', {...data, session: !remember})
            })
    },

    RESEND_VERIFICATION_CODE({commit, dispatch}, {}) {
        let url = 'auth/2fa'

        return axios.get(url, config.AXIOS_DEFAULTS)
            .then(data => {
                let successMessage = [i18n.t('verification.page.notification')]

                commit('setNotification', successMessage)
            })
    },

    STORE_USER({commit}, {profile, token, session}) {
        commit('storeUserProfile', profile)
        commit('storeUserToken', token)
        commit('storeUserSession', session)
        router.push({name: 'OfferList'})
    },

    LOGOUT_USER({commit}) {
        return axios.post('/auth/logout', null, config.AXIOS_DEFAULTS)
            .then(() => {
                commit('clearUser')
                router.push('/')
            })
    },

    GO_TO_FIRST_AVAILABLE_LIST_PAGE({state}) {
        if (state.profile && state.profile.permissions) {
            if (state.profile.permissions.hasOffers) {
                router.push({name: 'OfferList'})
            } else if (state.profile.permissions.hasOrders) {
                router.push({name: 'OrderList'})
            } else {
                router.push('/')
            }
        } else {
            router.push('/')
        }
    },

    //***** FETCH *****//
    FETCH_ORDER_PAGES({dispatch, state}) {
        return Promise.all(
            Object.keys(state.orders.pages).map(page => {
                return dispatch('FETCH_ORDERS', {page: page, keepOtherPages: true})
            })
        )
    },

    FETCH_ORDERS({commit, state}, {page, keepOtherPages}) {
        return axios.get('orders', {...config.AXIOS_DEFAULTS, params: {...state.orderListActiveFilters, page}})
            .then(response => {
                if (response) {
                    commit('setOrders', {response, keepOtherPages})

                    let newActiveFilters = {...state.orderListActiveFilters}

                    if (!newActiveFilters.filter || !response.data.filters.find(v => v.handle === newActiveFilters.filter)) {
                        newActiveFilters.filter = response.data.filters[0].handle
                    }

                    commit('setOrderListActiveFilters', newActiveFilters)
                }
            })
    },

    FETCH_OFFER_PAGES({dispatch, state}) {
        return Promise.all(
            Object.keys(state.offers.pages).map(page => {
                return dispatch('FETCH_OFFERS', {page: page, keepOtherPages: true})
            })
        )
    },

    FETCH_OFFERS({commit, state}, {page, keepOtherPages}) {
        return axios.get('offers', {...config.AXIOS_DEFAULTS, params: {...state.offerListActiveFilters, page}})
            .then(response => {
                if (response) {
                    commit('setOffers', {response, keepOtherPages})

                    let newActiveFilters = {...state.offerListActiveFilters}

                    if (!newActiveFilters.filter || !response.data.filters.find(v => v.handle === newActiveFilters.filter)) {
                        newActiveFilters.filter = response.data.filters[0].handle
                    }

                    commit('setOfferListActiveFilters', newActiveFilters)
                }
            })
    },

    FETCH_PROJECTS({commit, state}, {page, keepOtherPages}) {
        return axios.get('projects/forlist', {...config.AXIOS_DEFAULTS, params: {...state.projectListActiveFilters, page}})
            .then(response => {
                if (response) {
                    commit('setProjects', {response, keepOtherPages})

                    let newActiveFilters = {...state.projectListActiveFilters}

                    if (!newActiveFilters.filter || !response.data.filters.find(v => v.handle === newActiveFilters.filter)) {
                        newActiveFilters.filter = response.data.filters[0].handle
                    }

                    commit('setProjectListActiveFilters', newActiveFilters)
                }
            })
    },

    DELETE_PROJECT({commit, state}, payload){
        return axios.delete(`projects/delete/${payload.id}`, config.AXIOS_DEFAULTS).then(data => {
            let successMessage = ['Sikeres törlés!']
            commit('setNotification', successMessage)
        })
    },

    FETCH_OFFER_LIST({commit}) {
        return axios.get('offers-for-object-create', config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setOfferList', data)
            })
    },

    FETCH_OFFER_DATA({commit, state}, payload) {
        let apiUrl = `offers/create/${payload.projectId}/${payload.groupId}`

        if (payload.offerId) {
            apiUrl = `offers/${payload.offerId}`
        }

        if (payload.relatedOfferId) {
            apiUrl = `offers/create-related/${payload.relatedOfferId}/${payload.groupId}`
        }

        commit('resetOffer')

        return axios.get(apiUrl, config.AXIOS_DEFAULTS)
            .then(data => {
                loadOffer(commit, data)
                return data
            })
            .catch(e => {
                commit('setNotification', [e.message])
                router.back()
            })
    },

    FETCH_GROUP_TRANSPORTS({commit, state}, payload) {
        let apiUrl = `transports`

        return axios.get(apiUrl, config.AXIOS_DEFAULTS)
            .then(data => {
                loadGroupTransports(commit, data, state.orderDataTabs[state.selectedOrderDataTab])
                return data
            })
            .catch(e => {
                commit('setNotification', [e.message])
                router.back()
            })
    },

    FETCH_OFFER_INFORMATION_DATA({commit}, {projectId, offerInformation}) {
        if (offerInformation.id) {
            return axios.get(`offers/${offerInformation.id}`, config.AXIOS_DEFAULTS).then(data => {
                commit('setOfferInformationDealers', data.offer.dealerUsers)
                commit('setOfferInformationWorkers', data.offer.workerUsers)
                commit('setOfferInformationEditorUser', data.offer.editorUser)
                commit('setOfferInformationViewType', data.offer.viewType)
                commit('setPermissions', data.permissions)
                commit('setViewOnly', !data.permissions.canEditOffer)
            })
        } else {
            return Promise.all([
                axios.get(`projects/${projectId}/dealers`, config.AXIOS_DEFAULTS).then(data => {
                    if (offerInformation.editorUser) {
                        if (offerInformation.dealerUsers && offerInformation.dealerUsers.length > 0) {
                            offerInformation.dealerUsers.forEach((dealerUser) => {
                                if (dealerUser.id === offerInformation.editorUser.id) {
                                    commit('setOfferInformationEditorUser', offerInformation.ownerUser)
                                }
                            })
                        }
                    }
                    commit('setOfferInformationDealers', data)
                }),
                axios.get(`projects/${projectId}/workers`, config.AXIOS_DEFAULTS).then(data => {
                    if (offerInformation.editorUser) {
                        if (offerInformation.workerUsers && offerInformation.workerUsers.length > 0) {
                            offerInformation.workerUsers.forEach((workerUser) => {
                                if (workerUser.id === offerInformation.editorUser.id) {
                                    commit('setOfferInformationEditorUser', offerInformation.ownerUser)
                                }
                            })
                        }
                    }
                    commit('setOfferInformationWorkers', data)
                })
            ])
        }
    },

    FETCH_ORDER_DATA({state, commit}, payload) {
        let apiUrl = `orders/create/${payload.groupId}`

        if (payload.orderId !== null) {
            apiUrl = `orders/${payload.orderId}`
        }

        commit('resetOrder')

        return axios.get(apiUrl, config.AXIOS_DEFAULTS)
            .then(data => {
                loadOrder(commit, data)
                return true
            }).catch((err) => {
                return Promise.reject(err)
            })
    },

    FETCH_ORDER_DETAILS({state, commit}, payload) {
        let apiUrl = `orders/${payload.id}/details`

        if (payload.type === 'offer') {
            apiUrl = `orders/details-by-offer/${payload.id}`

            if (payload.discountType !== null) {
                apiUrl = `orders/details-by-offer/${payload.id}/${payload.discountType}`
            }
        }

        commit('resetOrderDetails')

        return axios.get(apiUrl, config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setOrderDetails', data)
                commit('setTitle', data.title)
                commit('setProjectData', null)
            })
    },

    FETCH_ORDER_STATUS({state, commit}, payload) {
        return axios.get(`orders/${payload.id}/data`, config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setOrderStatus', data)
            })
    },

    UPDATE_COMMENT({commit, dispatch}, payload) {
        return axios.patch(`orders/${payload.id}/updateComment`, {payload}, config.AXIOS_DEFAULTS)
    },

    FETCH_PRODUCT_GROUPS() {
        return axios.get('category-groups', config.AXIOS_DEFAULTS)
    },

    FETCH_GROUP_TYPES({commit}, groupId) {
        return axios.get(`groups/${groupId}/types`, config.AXIOS_DEFAULTS)
    },

    FETCH_GROUP_CATEGORY_PRODUCTS({commit}, groupId) {
        return axios.get(`groups/${groupId}/category-products`, config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setGroupCategoryProductList', data)
            })
    },

    FETCH_GROUP_CATEGORY_PRODUCT_TYPES({commit}, groupId) {
        return axios.get(`groups/${groupId}/category-product-types`, config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setGroupCategoryProductTypeList', data)
            })
    },

    FETCH_GROUP_CATEGORY_SORT({commit}, groupId) {
        return axios.get(`groups/${groupId}/category-sort`, config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setGroupCategorySort', data)
            })
    },

    SEND_GROUP_CATEGORY_SORT({commit}, {groupId, data}) {
        return axios.put(`groups/${groupId}/category-sort`, {data}, config.AXIOS_DEFAULTS).then(() => {
            commit('setNotification', [i18n.t('group_category_sort.notification.saved')])
            return data
        }).catch((err) => {
            commit('setNotification', [i18n.t('group_category_sort.notification.failed')])
            return Promise.reject(err)
        })
    },

    FETCH_GROUP_TYPE_SORT({commit}, groupId) {
        return axios.get(`groups/${groupId}/type-sort`, config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setGroupTypeSort', data)
            })
    },

    SEND_GROUP_TYPE_SORT({commit}, {groupId, data}) {
        return axios.put(`groups/${groupId}/type-sort`, {data}, config.AXIOS_DEFAULTS).then(() => {
            commit('setNotification', [i18n.t('group_type_sort.notification.saved')])
            return data
        }).catch((err) => {
            commit('setNotification', [i18n.t('group_type_sort.notification.failed')])
            return Promise.reject(err)
        })
    },

    FETCH_GROUP_TYPE_VARIANTS({commit}, groupId) {
        return axios.get(`groups/${groupId}/type-variants`, config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setGroupTypeVariantList', data)
            })
    },

    FETCH_GROUP_PRODUCTS({commit}, groupId) {
        return axios.get(`groups/${groupId}/products`, config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setGroupProductList', data)
            })
    },

    FETCH_PRODUCT_TYPES({commit}) {
        return axios.get(`product-types`, config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setProductTypeList', data)
            })
    },

    FETCH_COLORS({commit}) {
        return axios.get(`colors`, config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setColorList', data)
            })
    },

    FETCH_UNITS({commit}) {
        return axios.get(`units`, config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setUnitList', data)
            })
    },

    FETCH_CARRIERS({commit}) {
        return axios.get(`carriers`, config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setCarrierList', data)
            })
    },

    FETCH_SURFACES({commit}) {
        return axios.get(`surfaces`, config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setSurfaceList', data)
            })
    },

    FETCH_OFFER_PRODUCT_GROUPS({commit}) {
        return axios.get('offer-groups', config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setOfferProductTypes', data)
            })
    },

    FETCH_OFFER_ADVISORIES({commit}) {
        return axios.get('users/advisories', config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setAdvisories', data)
            })
            .catch((err) => {
                commit('setAdvisories', [])
            })
    },

    FETCH_OFFER_DEALERS({commit}) {
        return axios.get('users/dealers', config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setAllDealers', data)
            })
            .catch((err) => {
                commit('setAllDealers', [])
            })
    },

    FETCH_ORDER_PRODUCT_GROUPS({commit}) {
        return axios.get('order-groups', config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setOrderProductTypes', data)
            })
    },

    FETCH_OBJECT_STANDALONE_TYPES({commit}) {
        return axios.get('object-standalone-types', config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setObjectStandaloneTypes', data)
            })
    },

    FETCH_NEW_PROJECT_DATA() {
        return axios.get('projects/create', config.AXIOS_DEFAULTS)
    },

    FETCH_PROJECT_OFFERS({commit}, projectId) {
        return axios.get(`projects/${projectId}/offers`, config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setProjectOffers', data)
            })
    },

    FETCH_PROJECT_DEALERS({commit}) {
        return axios.get('project-dealers', config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setProjectDealers', data)
            })
    },

    FETCH_DEALER_PAGES({dispatch, state}) {
        return Promise.all(
            Object.keys(state.dealers.pages).map(page => {
                return dispatch('FETCH_DEALERS', {page: page, keepOtherPages: true})
            })
        )
    },

    FETCH_DEALERS({commit, state}, {page, keepOtherPages}) {
        return axios.get('dealers', {...config.AXIOS_DEFAULTS, params: {...state.dealerListActiveFilters, page}})
            .then(response => {
                if (response) {
                    commit('setDealers', {response, keepOtherPages})

                    let newActiveFilters = {...state.dealerListActiveFilters}

                    if (!newActiveFilters.filter || !response.data.filters.find(v => v.handle === newActiveFilters.filter)) {
                        newActiveFilters.filter = response.data.filters[0].handle
                    }

                    commit('setDealerListActiveFilters', newActiveFilters)
                }
            })
    },

    FETCH_WORKER_PAGES({dispatch, state}) {
        return Promise.all(
            Object.keys(state.workers.pages).map(page => {
                return dispatch('FETCH_WORKERS', {page: page, keepOtherPages: true})
            })
        )
    },

    FETCH_WORKERS({commit, state}, {page, keepOtherPages}) {
        return axios.get('workers', {...config.AXIOS_DEFAULTS, params: {...state.workerListActiveFilters, page}})
            .then(response => {
                if (response) {
                    commit('setWorkers', {response, keepOtherPages})
                }
            })
    },

    FETCH_REGIONS({commit}) {
        return axios.get('regions', config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setRegionList', data)
            })
    },

    FETCH_REGION({commit}, regionId) {
        return axios.get(`regions/${regionId}`, config.AXIOS_DEFAULTS)
    },

    SEND_REGION({commit}, payload) {
        return axios.patch(`regions/${payload.id}`, payload.data, config.AXIOS_DEFAULTS)
            .then(data => {
                let successMessage = [i18n.t('region.manage.notification')]

                commit('setNotification', successMessage)

                return data
            })
    },

    FETCH_PRODUCT_PAGES({dispatch, state}) {
        return Promise.all(
            Object.keys(state.products.pages).map(page => {
                return dispatch('FETCH_PRODUCTS', {page: page, keepOtherPages: true})
            })
        )
    },

    FETCH_PRODUCTS({commit, state}, {page, keepOtherPages}) {
        return axios.get('products', {...config.AXIOS_DEFAULTS, params: {...state.productListActiveFilters, page}})
            .then(response => {
                if (response) {
                    commit('setProducts', {response, keepOtherPages})
                }
            })
    },

    DELETE_PRODUCT({commit, dispatch}, payload) {
        return axios.delete(`products/${payload.id}`, config.AXIOS_DEFAULTS)
    },

    RESTORE_PRODUCT({commit, dispatch}, payload) {
        return axios.post(`products/${payload.id}/restore`, {}, config.AXIOS_DEFAULTS)
    },

    FETCH_GROUP_TYPE_PRODUCTS({commit}, {groupId, groupTypeId, groupTypeVariantId}) {
        let url

        if (groupTypeVariantId) {
            url = `groups/${groupId}/variants/${groupTypeVariantId}/products`
        } else {
            url = `groups/${groupId}/types/${groupTypeId}/products`
        }

        return axios.get(url, config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setGroupTypeProductList', data)
            })
    },

    FETCH_PRODUCT({commit}, {productId, productNumber}) {
        if(productId) {
            return axios.get(`products/${productId}`, config.AXIOS_DEFAULTS)
        } else {
            return axios.get(`products/create/${productNumber}`, config.AXIOS_DEFAULTS)
        }
    },

    FETCH_GROUP_TYPE_PRODUCT({commit}, {groupId, groupTypeId, groupTypeVariantId, productId}) {
        let url

        if (groupTypeVariantId) {
            url = `groups/${groupId}/variants/${groupTypeVariantId}/products/${productId}`
        } else {
            url = `groups/${groupId}/types/${groupTypeId}/products/${productId}`
        }

        return axios.get(url, config.AXIOS_DEFAULTS)
    },

    FETCH_GROUP_CATEGORY({commit}, {groupId, categoryId, createTarget}) {
        if(createTarget) {
            return axios.get(`groups/${groupId}/categories/create/${createTarget}/${categoryId}`, config.AXIOS_DEFAULTS)
        } else {
            return axios.get(`groups/${groupId}/categories/${categoryId}`, config.AXIOS_DEFAULTS)
        }
    },

    FETCH_GROUP_CATEGORY_PRODUCT({commit}, {groupId, categoryId, productId, createTarget}) {
        if(createTarget) {
            const postfix = productId === null ? '' : `/${productId}`
            return axios.get(`groups/${groupId}/categories/${categoryId}/products/create/${createTarget}${postfix}`, config.AXIOS_DEFAULTS)
        } else {
            return axios.get(`groups/${groupId}/categories/${categoryId}/products/${productId}`, config.AXIOS_DEFAULTS)
        }
    },

    FETCH_GROUP_CATEGORY_PRODUCT_TYPE({commit}, {groupId, categoryId, productTypeId, createTarget}) {
        if(createTarget) {
            const postfix = productTypeId === null ? '' : `/${productTypeId}`
            return axios.get(`groups/${groupId}/categories/${categoryId}/product-types/create/${createTarget}${postfix}`, config.AXIOS_DEFAULTS)
        } else {
            return axios.get(`groups/${groupId}/categories/${categoryId}/product-types/${productTypeId}`, config.AXIOS_DEFAULTS)
        }
    },

    FETCH_GROUP_TYPE_CATEGORY({commit}, {groupId, categoryId, createTarget}) {
        if(createTarget) {
            return axios.get(`groups/${groupId}/type-categories/create/${createTarget}/${categoryId}`, config.AXIOS_DEFAULTS)
        } else {
            return axios.get(`groups/${groupId}/type-categories/${categoryId}`, config.AXIOS_DEFAULTS)
        }
    },

    FETCH_GROUP_TYPE({commit}, {groupId, categoryId, typeId, createTarget}) {
        if(createTarget) {
            const postfix = typeId === null ? '' : `/${typeId}`
            return axios.get(`groups/${groupId}/type-categories/${categoryId}/types/create/${createTarget}${postfix}`, config.AXIOS_DEFAULTS)
        } else {
            return axios.get(`groups/${groupId}/type-categories/${categoryId}/types/${typeId}`, config.AXIOS_DEFAULTS)
        }
    },

    FETCH_GROUP_TYPE_VARIANT({commit}, {groupId, categoryId, typeId, variantId, createTarget}) {
        if(createTarget) {
            const postfix = variantId === null ? '' : `/${variantId}`
            return axios.get(`groups/${groupId}/type-categories/${categoryId}/types/${typeId}/variants/create/${createTarget}${postfix}`, config.AXIOS_DEFAULTS)
        } else {
            return axios.get(`groups/${groupId}/type-categories/${categoryId}/types/${typeId}/variants/${variantId}`, config.AXIOS_DEFAULTS)
        }
    },

    FETCH_GROUP_PRODUCT({commit}, {groupId, groupProductId}) {
        if(groupProductId) {
            return axios.get(`groups/${groupId}/products/${groupProductId}`, config.AXIOS_DEFAULTS)
        } else {
            return axios.get(`groups/${groupId}/products/create`, config.AXIOS_DEFAULTS)
        }
    },

    FETCH_PRODUCT_TYPE({commit}, {productTypeId}) {
        if(productTypeId) {
            return axios.get(`product-types/${productTypeId}`, config.AXIOS_DEFAULTS)
        } else {
            return axios.get(`product-types/create`, config.AXIOS_DEFAULTS)
        }
    },

    FETCH_COLOR({commit}, {colorId}) {
        if(colorId) {
            return axios.get(`colors/${colorId}`, config.AXIOS_DEFAULTS)
        } else {
            return axios.get(`colors/create`, config.AXIOS_DEFAULTS)
        }
    },

    FETCH_UNIT({commit}, {unitId}) {
        if(unitId) {
            return axios.get(`units/${unitId}`, config.AXIOS_DEFAULTS)
        } else {
            return axios.get(`units/create`, config.AXIOS_DEFAULTS)
        }
    },

    FETCH_SURFACE({commit}, {surfaceId}) {
        if(surfaceId) {
            return axios.get(`surfaces/${surfaceId}`, config.AXIOS_DEFAULTS)
        } else {
            return axios.get(`surfaces/create`, config.AXIOS_DEFAULTS)
        }
    },

    FETCH_CARRIER({commit}, {carrierId}) {
        if(carrierId) {
            return axios.get(`carriers/${carrierId}`, config.AXIOS_DEFAULTS)
        } else {
            return axios.get(`carriers/create`, config.AXIOS_DEFAULTS)
        }
    },

    FETCH_PRICE_LIST_DATE({commit}) {
        return axios.get(`price-list-date`, config.AXIOS_DEFAULTS)
    },

    FETCH_USER_PAGES({dispatch, state}) {
        return Promise.all(
            Object.keys(state.users.pages).map(page => {
                return dispatch('FETCH_USERS', {page: page, keepOtherPages: true})
            })
        )
    },

    FETCH_USERS({commit, state}, {page, keepOtherPages}) {
        return axios.get('users', {...config.AXIOS_DEFAULTS, params: {...state.userListActiveFilters, page}})
            .then(response => {
                if (response) {
                    commit('setUsers', {response, keepOtherPages})

                    let newActiveFilters = {...state.userListActiveFilters}

                    if (!newActiveFilters.filter || !response.data.filters.find(v => v.handle === newActiveFilters.filter)) {
                        newActiveFilters.filter = response.data.filters[0].handle
                    }

                    commit('setUserListActiveFilters', newActiveFilters)
                }
            })
    },

    FETCH_ALL_USERS({commit, state}) {
        return axios.get('users/all', {...config.AXIOS_DEFAULTS, params: {...state.userListActiveFilters}})
            .then(response => {
                if (response) {
                    commit('setAllUsers', {response})
                }
            })
    },

    FETCH_ALL_USERS_WITHOUT_FILTERS({commit, state}) {
        return axios.get('users/all', {...config.AXIOS_DEFAULTS, params: {}})
            .then(response => {
                if (response) {
                    commit('setAllUsersWithoutFilters', {response})
                }
            })
    },

    FETCH_USER_ROLES({commit}) {
        return axios.get('user-roles', config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setUserRoles', data)
            })
    },

    FETCH_ALL_USER_ROLES({commit}) {
        return axios.get('all-user-roles', config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setAllUserRoles', data)
            })
    },

    DELETE_USER({commit, dispatch}, payload) {
        return axios.delete(`users/${payload.id}/new-user/${payload.newUserId}`, config.AXIOS_DEFAULTS)
    },

    FORCE_DELETE_USER({commit, dispatch}, payload) {
        return axios.delete(`users/force-delete/${payload.id}`, config.AXIOS_DEFAULTS)
    },

    RESTORE_USER({commit, dispatch}, payload) {
        return axios.post(`users/${payload.id}/restore`, {}, config.AXIOS_DEFAULTS)
    },

    FETCH_DEALER({commit}, dealerId) {
        let url

        if (dealerId === null) {
            url = 'dealers/create'
        } else {
            url = `dealers/${dealerId}`
        }

        return axios.get(url, config.AXIOS_DEFAULTS)
    },

    DELETE_DEALER({commit, dispatch}, payload) {
        return axios.delete(`dealers/${payload.id}`, config.AXIOS_DEFAULTS)
    },

    FORCE_DELETE_DEALER({commit, dispatch}, payload) {
        return axios.delete(`dealers/force-delete/${payload.id}`, config.AXIOS_DEFAULTS)
    },

    SEND_DEALER({commit}, payload) {
        let url, method

        if (payload.id === null) {
            url = 'dealers'
            method = 'post'
        } else {
            url = `dealers/${payload.id}`
            method = 'put'
        }

        return axios[method](url, payload.data, config.AXIOS_DEFAULTS)
            .then(data => {
                let successMessage = [
                    i18n.t('dealer.manage.notification.store.first_row'),
                    i18n.t('dealer.manage.notification.store.second_row')
                ]

                if (payload.id !== null) {
                    successMessage = [i18n.t('dealer.manage.notification.update')]
                }

                commit('setNotification', successMessage)

                return data
            })
    },

    FETCH_WORKER({commit}, workerId) {
        let url

        if (workerId === null) {
            url = 'workers/create'
        } else {
            url = `workers/${workerId}`
        }

        return axios.get(url, config.AXIOS_DEFAULTS)
    },

    DELETE_WORKER({commit, dispatch}, payload) {
        return axios.delete(`workers/${payload.id}`, config.AXIOS_DEFAULTS)
    },

    SEND_WORKER({commit}, payload) {
        let url, method

        if (payload.id === null) {
            url = 'workers'
            method = 'post'
        } else {
            url = `workers/${payload.id}`
            method = 'put'
        }

        return axios[method](url, payload.data, config.AXIOS_DEFAULTS)
            .then(data => {
                let successMessage = [i18n.t('worker.manage.notification.store')]

                if (payload.id !== null) {
                    successMessage = [i18n.t('worker.manage.notification.update')]
                }

                commit('setNotification', successMessage)

                return data
            })
    },

    FETCH_WORKER_PROJECT_LIST({commit}, payload) {
        return axios.get(`workers/${payload}/projects`, config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setWorkerProjectList', data)
            })
    },

    SAVE_WORKER_PROJECT({commit}, {projectId, workerId}) {
        return axios.patch(`projects/${projectId}/worker`, {workerId}, config.AXIOS_DEFAULTS)
            .then(() => {
                let successMessage = [i18n.t('worker.project_select.notification')]
                commit('setNotification', successMessage)
            })
    },

    RENAME_PROJECT({commit}, {id, name}) {
        return axios.patch(`projects/${id}/name`, {name}, config.AXIOS_DEFAULTS)
            .then(() => {
                let successMessage = [i18n.t('project.rename.notification')]
                commit('setNotification', successMessage)
            })
    },

    FETCH_WORKERS_ALL({commit}) {
        return axios.get(`project-workers`, config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setWorkersAll', data)
            })
    },

    RESEND_USER_INVITE({commit}, userId) {
        return axios.post(`users/${userId}/resend-invite`, {}, config.AXIOS_DEFAULTS)
            .then(data => {
                let successMessage = [i18n.t('user.page.list.confirmation.invite.notification')]

                commit('setNotification', successMessage)

                return data
            })
    },

    SEND_PRODUCT({commit}, payload) {
        let request

        if(payload.productId) {
            request = axios.put(`products/${payload.productId}`, payload.data, config.AXIOS_DEFAULTS)
        } else {
            request = axios.post(`products/${payload.productNumber}`, payload.data, config.AXIOS_DEFAULTS)
        }

        return request.then(data => {
            let successMessage

            if(payload.productId) {
                successMessage = [i18n.t('product.manage.notification.updated')]
            } else {
                successMessage = [i18n.t('product.manage.notification.created')]
            }

            commit('setNotification', successMessage)

            return data
        })
    },

    SEND_GROUP_TYPE_PRODUCT({commit}, {groupId, groupTypeId, groupTypeVariantId, productId, data}) {
        let url

        if (groupTypeVariantId) {
            url = `groups/${groupId}/variants/${groupTypeVariantId}/products/${productId}`
        } else {
            url = `groups/${groupId}/types/${groupTypeId}/products/${productId}`
        }

        return axios.put(url, data, config.AXIOS_DEFAULTS)
            .then(data => {
                let successMessage = [i18n.t('group_type_product.manage.notification')]

                commit('setNotification', successMessage)

                return data
            })
    },

    SEND_GROUP_CATEGORY({commit}, {groupId, categoryId, createTarget, data}) {
        let request

        if(createTarget) {
            request = axios.post(`groups/${groupId}/categories/create/${createTarget}/${categoryId}`, data, config.AXIOS_DEFAULTS)
        } else {
            request = axios.put(`groups/${groupId}/categories/${categoryId}`, data, config.AXIOS_DEFAULTS)
        }

        return request.then(data => {
            let successMessage

            if(createTarget) {
                successMessage = [i18n.t('group_category.manage.notification.created')]
            } else {
                successMessage = [i18n.t('group_category.manage.notification.updated')]
            }

            commit('setNotification', successMessage)

            return data
        })
    },

    SEND_GROUP_CATEGORY_PRODUCT({commit}, {groupId, categoryId, productId, createTarget, data}) {
        let request

        if(createTarget) {
            const postfix = productId === null ? '' : `/${productId}`
            request = axios.post(`groups/${groupId}/categories/${categoryId}/products/create/${createTarget}${postfix}`, data, config.AXIOS_DEFAULTS)
        } else {
            request = axios.put(`groups/${groupId}/categories/${categoryId}/products/${productId}`, data, config.AXIOS_DEFAULTS)
        }

        return request.then(data => {
            let successMessage

            if(createTarget) {
                successMessage = [i18n.t('group_category_product.modal.notification.created')]
            } else {
                successMessage = [i18n.t('group_category_product.modal.notification.updated')]
            }

            commit('setNotification', successMessage)

            return data
        })
    },

    SEND_GROUP_CATEGORY_PRODUCT_TYPE({commit}, {groupId, categoryId, productTypeId, createTarget, data}) {
        let request

        if(createTarget) {
            const postfix = productTypeId === null ? '' : `/${productTypeId}`
            request = axios.post(`groups/${groupId}/categories/${categoryId}/product-types/create/${createTarget}${postfix}`, data, config.AXIOS_DEFAULTS)
        } else {
            request = axios.put(`groups/${groupId}/categories/${categoryId}/product-types/${productTypeId}`, data, config.AXIOS_DEFAULTS)
        }

        return request.then(data => {
            let successMessage

            if(createTarget) {
                successMessage = [i18n.t('group_category_product_type.modal.notification.created')]
            } else {
                successMessage = [i18n.t('group_category_product_type.modal.notification.updated')]
            }

            commit('setNotification', successMessage)

            return data
        })
    },

    SEND_GROUP_TYPE_CATEGORY({commit}, {groupId, categoryId, createTarget, data}) {
        let request

        if(createTarget) {
            request = axios.post(`groups/${groupId}/type-categories/create/${createTarget}/${categoryId}`, data, config.AXIOS_DEFAULTS)
        } else {
            request = axios.put(`groups/${groupId}/type-categories/${categoryId}`, data, config.AXIOS_DEFAULTS)
        }

        return request.then(data => {
            let successMessage

            if(createTarget) {
                successMessage = [i18n.t('group_type_category.manage.notification.created')]
            } else {
                successMessage = [i18n.t('group_type_category.manage.notification.updated')]
            }

            commit('setNotification', successMessage)

            return data
        })
    },

    SEND_GROUP_TYPE({commit}, {groupId, categoryId, typeId, createTarget, data}) {
        let request

        if(createTarget) {
            const postfix = typeId === null ? '' : `/${typeId}`
            request = axios.post(`groups/${groupId}/type-categories/${categoryId}/types/create/${createTarget}${postfix}`, data, config.AXIOS_DEFAULTS)
        } else {
            request = axios.put(`groups/${groupId}/type-categories/${categoryId}/types/${typeId}`, data, config.AXIOS_DEFAULTS)
        }

        return request.then(data => {
            let successMessage

            if(createTarget) {
                successMessage = [i18n.t('group_type.manage.notification.created')]
            } else {
                successMessage = [i18n.t('group_type.manage.notification.updated')]
            }

            commit('setNotification', successMessage)

            return data
        })
    },

    SEND_GROUP_TYPE_VARIANT({commit}, {groupId, categoryId, typeId, variantId, createTarget, data}) {
        let request

        if(createTarget) {
            const postfix = variantId === null ? '' : `/${variantId}`
            request = axios.post(`groups/${groupId}/type-categories/${categoryId}/types/${typeId}/variants/create/${createTarget}${postfix}`, data, config.AXIOS_DEFAULTS)
        } else {
            request = axios.put(`groups/${groupId}/type-categories/${categoryId}/types/${typeId}/variants/${variantId}`, data, config.AXIOS_DEFAULTS)
        }

        return request.then(data => {
            let successMessage

            if(createTarget) {
                successMessage = [i18n.t('group_type_variant.manage.notification.created')]
            } else {
                successMessage = [i18n.t('group_type_variant.manage.notification.updated')]
            }

            commit('setNotification', successMessage)

            return data
        })
    },

    SEND_GROUP_PRODUCT({commit}, {groupId, groupProductId, data}) {
        let request

        if(groupProductId) {
            request = axios.put(`groups/${groupId}/products/${groupProductId}`, data, config.AXIOS_DEFAULTS)
        } else {
            request = axios.post(`groups/${groupId}/products/create`, data, config.AXIOS_DEFAULTS)
        }

        return request.then(data => {
            let successMessage

            if(groupProductId) {
                successMessage = [i18n.t('group_product.manage.notification.updated')]
            } else {
                successMessage = [i18n.t('group_product.manage.notification.created')]
            }

            commit('setNotification', successMessage)

            return data
        })
    },

    SEND_PRODUCT_TYPE({commit}, {productTypeId, data}) {
        let request

        if(productTypeId) {
            request = axios.put(`product-types/${productTypeId}`, data, config.AXIOS_DEFAULTS)
        } else {
            request = axios.post(`product-types/create`, data, config.AXIOS_DEFAULTS)
        }

        return request.then(data => {
            let successMessage

            if(productTypeId) {
                successMessage = [i18n.t('product_type.manage.notification.updated')]
            } else {
                successMessage = [i18n.t('product_type.manage.notification.created')]
            }

            commit('setNotification', successMessage)

            return data
        })
    },

    SEND_COLOR({commit}, {colorId, data}) {
        let request

        if(colorId) {
            request = axios.put(`colors/${colorId}`, data, config.AXIOS_DEFAULTS)
        } else {
            request = axios.post(`colors/create`, data, config.AXIOS_DEFAULTS)
        }

        return request.then(data => {
            let successMessage

            if(colorId) {
                successMessage = [i18n.t('color.manage.notification.updated')]
            } else {
                successMessage = [i18n.t('color.manage.notification.created')]
            }

            commit('setNotification', successMessage)

            return data
        })
    },

    SEND_UNIT({commit}, {unitId, data}) {
        let request

        if(unitId) {
            request = axios.put(`units/${unitId}`, data, config.AXIOS_DEFAULTS)
        } else {
            request = axios.post(`units/create`, data, config.AXIOS_DEFAULTS)
        }

        return request.then(data => {
            let successMessage

            if(unitId) {
                successMessage = [i18n.t('unit.manage.notification.updated')]
            } else {
                successMessage = [i18n.t('unit.manage.notification.created')]
            }

            commit('setNotification', successMessage)

            return data
        })
    },

    SEND_CARRIER({commit}, {carrierId, data}) {
        let request

        if(carrierId) {
            request = axios.put(`carriers/${carrierId}`, data, config.AXIOS_DEFAULTS)
        } else {
            request = axios.post(`carriers/create`, data, config.AXIOS_DEFAULTS)
        }

        return request.then(data => {
            let successMessage

            if(carrierId) {
                successMessage = [i18n.t('carrier.manage.notification.updated')]
            } else {
                successMessage = [i18n.t('carrier.manage.notification.created')]
            }

            commit('setNotification', successMessage)

            return data
        })
    },

    SEND_SURFACE({commit}, {surfaceId, data}) {
        let request

        if(surfaceId) {
            request = axios.put(`surfaces/${surfaceId}`, data, config.AXIOS_DEFAULTS)
        } else {
            request = axios.post(`surfaces/create`, data, config.AXIOS_DEFAULTS)
        }

        return request.then(data => {
            let successMessage

            if(surfaceId) {
                successMessage = [i18n.t('surface.manage.notification.updated')]
            } else {
                successMessage = [i18n.t('surface.manage.notification.created')]
            }

            commit('setNotification', successMessage)

            return data
        })
    },

    SEND_PRICE_LIST_DATE({commit}, {data}) {
        return axios.put(`price-list-date`, data, config.AXIOS_DEFAULTS).then(data => {
            let successMessage = [i18n.t('price_list_date.manage.notification.updated')]

            commit('setNotification', successMessage)

            return data
        })
    },

    DELETE_GROUP_CATEGORY({commit, dispatch}, {groupId, categoryId}) {
        return axios.delete(`groups/${groupId}/categories/${categoryId}`, config.AXIOS_DEFAULTS)
    },

    DELETE_GROUP_CATEGORY_PRODUCT({commit, dispatch}, {groupId, categoryId, productId}) {
        return axios.delete(`groups/${groupId}/categories/${categoryId}/products/${productId}`, config.AXIOS_DEFAULTS)
    },

    DELETE_GROUP_CATEGORY_PRODUCT_TYPE({commit, dispatch}, {groupId, categoryId, productTypeId}) {
        return axios.delete(`groups/${groupId}/categories/${categoryId}/product-types/${productTypeId}`, config.AXIOS_DEFAULTS)
    },

    DELETE_GROUP_TYPE_CATEGORY({commit, dispatch}, {groupId, categoryId}) {
        return axios.delete(`groups/${groupId}/type-categories/${categoryId}`, config.AXIOS_DEFAULTS)
    },

    DELETE_GROUP_TYPE({commit, dispatch}, {groupId, categoryId, typeId}) {
        return axios.delete(`groups/${groupId}/type-categories/${categoryId}/types/${typeId}`, config.AXIOS_DEFAULTS)
    },

    DELETE_GROUP_TYPE_VARIANT({commit, dispatch}, {groupId, categoryId, typeId, variantId}) {
        return axios.delete(`groups/${groupId}/type-categories/${categoryId}/types/${typeId}/variants/${variantId}`, config.AXIOS_DEFAULTS)
    },

    DELETE_GROUP_PRODUCT({commit, dispatch}, {groupId, groupProductId}) {
        return axios.delete(`groups/${groupId}/products/${groupProductId}`, config.AXIOS_DEFAULTS)
    },

    DELETE_PRODUCT_TYPE({commit, dispatch}, {productTypeId}) {
        return axios.delete(`product-types/${productTypeId}`, config.AXIOS_DEFAULTS)
    },

    DELETE_COLOR({commit, dispatch}, {colorId}) {
        return axios.delete(`colors/${colorId}`, config.AXIOS_DEFAULTS)
    },

    DELETE_UNIT({commit, dispatch}, {unitId}) {
        return axios.delete(`units/${unitId}`, config.AXIOS_DEFAULTS)
    },

    DELETE_CARRIER({commit, dispatch}, {carrierId}) {
        return axios.delete(`carriers/${carrierId}`, config.AXIOS_DEFAULTS)
    },

    DELETE_SURFACE({commit, dispatch}, {surfaceId}) {
        return axios.delete(`surfaces/${surfaceId}`, config.AXIOS_DEFAULTS)
    },

    FETCH_USER({commit}, {id, role}) {
        let url

        if (id === null) {
            if (role === null) {
                url = 'users/create'
            } else {
                url = `users/create/${role}`
            }
        } else {
            url = `users/${id}`
        }

        return axios.get(url, config.AXIOS_DEFAULTS)
    },

    SEND_USER({commit}, payload) {
        let url, method

        if (payload.id === null) {
            if (payload.role !== null) {
                url = `users/create/${payload.role}`
            } else {
                url = 'users/create'
            }
            method = 'post'
        } else {
            url = `users/${payload.id}`
            method = 'patch'
        }

        return axios[method](url, payload.data, config.AXIOS_DEFAULTS)
            .then(data => {
                let successMessage

                if (payload.id !== null) {
                    successMessage = [i18n.t('user.manage.notification.update')]
                } else {
                    successMessage = [
                        i18n.t('user.manage.notification.store.first_row'),
                        i18n.t('user.manage.notification.store.second_row')
                    ]
                }

                commit('setNotification', successMessage)

                return data
            })
    },

    FETCH_OBJECT_PAGES({dispatch, state}) {
        return Promise.all(
            Object.keys(state.objects.pages).map(page => {
                return dispatch('FETCH_OBJECTS', {page: page, keepOtherPages: true})
            })
        )
    },

    FETCH_OBJECTS({commit, state}, {page, keepOtherPages}) {
        return axios.get('objects', {...config.AXIOS_DEFAULTS, params: {...state.objectListActiveFilters, page}})
            .then(response => {
                if (response) {
                    commit('setObjects', {response, keepOtherPages})

                    let newActiveFilters = {...state.objectListActiveFilters}

                    if (!newActiveFilters.filter || !response.data.filters.find(v => v.handle === newActiveFilters.filter)) {
                        newActiveFilters.filter = response.data.filters[0].handle
                    }

                    commit('setObjectListActiveFilters', newActiveFilters)
                }
            })
    },

    FETCH_ZIPCODE_LIST({commit}) {
        return axios.get('zips', config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setZipCodesList', data)
            })
    },

    FETCH_CITY_LIST({commit}) {
        return axios.get('cities', config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setCitiesList', data)
            })
    },

    FETCH_SAVED_COMMENTS({commit}) {
        return axios.get('comments', config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setSavedComments', data)
            })
    },

    FETCH_USER_SETTINGS({commit}) {
        return axios.get('user-settings', config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setUserSettings', data)
            })
    },

    FETCH_CALCULATION({state, commit}) {
        let message = {}

        message.groupId = state.offerInformation.groupId
        message.variantId = state.orderData.selectedVariant.id
        message.calculation = state.orderData.calculation

        return axios.post('plan-calculations', message, config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setPlanCalculation', {isNew: !message.calculation, ...data})
            })
    },

    //***** UPDATE *****//
    UPDATE_CATEGORY_LIST({state, commit}, payload) {
        let url = 'orders/tab'
        let message = {
            previous: {},
            next: {}
        }

        if (payload.handle === 'groupType') {
            message.next.groupTypeId = payload.element.id
        }

        if (payload.handle === 'variant') {
            message.next.variantId = payload.element.id
        }

        if (payload.jobType === 'offer') {
            url = 'offers/tab'
            message.offerId = payload.offerId
            message.previous.calculation = state.orderData.calculation
        }

        if (payload.jobType === 'order') {
            message.orderTabId = payload.orderTabId
            message.currentGroupIds = state.orderDataTabs.map(t => t.groupId)
        }

        message.previous.groupId = payload.groupId
        message.previous.variantId = state.orderData.selectedVariant.id
        message.previous.discountType = state.discounts.discountType
        message.previous.discounts = state.discounts.groupDiscounts
        message.previous.products = getProductsWithValue(state.orderData.categories)

        return axios.patch(url, message, config.AXIOS_DEFAULTS)
            .then(data => {
                loadTab(commit, data, true) // old 3rd param: payload.jobType !== 'order'

                if (payload.jobType === 'order') {
                    commit('updateOrderDataTabs')
                }

                if (payload.jobType === 'offer') {
                    commit('setPlanCalculation', null)
                }
            })
    },

    UPDATE_CATEGORY_LIST_BY_PLAN({state, commit}, plan) {
        let message = {
            groupId: state.offerInformation.groupId,
            tab: {},
            plan: plan
        }

        message.tab.variantId = state.orderData.selectedVariant.id
        message.tab.discountType = state.discounts.discountType
        message.tab.discounts = state.discounts.groupDiscounts
        message.tab.products = getProductsWithValue(state.orderData.categories)

        return axios.post('plan-calculations/create-tab', message, config.AXIOS_DEFAULTS)
            .then(data => {
                loadTab(commit, data)

                let successMessage = [i18n.t('offer.plan_calculation.calculation.notification')]
                commit('setNotification', successMessage)
            })
    },

    UPDATE_OFFER_FAVORITE({commit, dispatch}, payload) { // payload {id, add}
        let updateUrl = `offers/${payload.id}/list`
        let data = {favorite: payload.add === true}

        return axios.patch(updateUrl, data, config.AXIOS_DEFAULTS)
            .then(() => {
                dispatch('FETCH_OFFERS', {page: payload.page, keepOtherPages: true})
            })
    },

    /*
    UPDATE_OFFER_ARCHIVE({commit, dispatch}, payload) { // payload {id, archive, inList}
        let updateUrl = `offers/${payload.id}/list`

        if (!payload.inList) {
            updateUrl = `offers/${payload.id}/archive`
        }

        let data = {archive: payload.archive === true}

        return axios.patch(updateUrl, data, config.AXIOS_DEFAULTS)
            .then(data => {
                if (payload.inList) {
                    dispatch('FETCH_OFFERS', {page: payload.page, keepOtherPages: true})
                } else {
                    commit('setOfferActions', data)
                }

                let successMessage = [i18n.t('offer.page.edit.actions.archive.notification')]
                if (!payload.archive) {
                    successMessage = [i18n.t('offer.page.edit.actions.unarchive.notification')]
                }

                commit('setNotification', successMessage)
            })
    },

    /ARCHIVE_OFFERS() {
        return axios.post('offers/archive', {}, config.AXIOS_DEFAULTS)
    },
    */

    FETCH_TAGS({commit}) {
        return axios.get('tags', config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setTags', data)
            })
    },

    ADD_OFFER_TAG({commit}, payload) {
        return axios.post('tags', payload, config.AXIOS_DEFAULTS)
            .then(data => {
                commit('refreshTags', data)
                commit('setValidation', '')
                commit('setNotification', [i18n.t('offer.tags.add.notification')])
            })
    },

    UPDATE_OFFER_TAGS({commit, dispatch}, payload) {
        return axios.patch(`offers/${payload.id}/list`, {tagIds: payload.tags}, config.AXIOS_DEFAULTS)
            .then(() => {
                commit('setNotification', [i18n.t('offer.tags.select.notification')])
                if (payload.inList) {
                    dispatch('FETCH_OFFERS', {page: payload.page, keepOtherPages: true})
                }
            })
    },

    REMOVE_OFFER_TAG({commit}, payload) {
        return axios.patch(`offers/${payload.id}/list`, {tag: {id: payload.tag, active: false}}, config.AXIOS_DEFAULTS)
            .then(() => {
                commit('setNotification', [i18n.t('offer.page.list.notifications.delete_offer_tag')])
            })
    },

    DELETE_OFFER_TAG_TYPE({commit}, payload) {
        return axios.delete(`tags/${payload.id}`, config.AXIOS_DEFAULTS)
            .then(data => {
                commit('refreshTags', data)
                commit('setValidation', '')
                commit('setNotification', [i18n.t('offer.page.list.notifications.delete_tag')])
            })
    },

    //*****  SAVE / SEND *****//
    SAVE_PROJECT({state, dispatch, commit}, payload) {
        let url, method

        if (payload.id) {
            url = `projects/${payload.id}`
            method = 'patch'
        } else {
            url = 'projects'
            method = 'post'
        }

        return axios[method](url, payload.data, config.AXIOS_DEFAULTS)
            .then(data => {
                if (payload.status === 'new') {
                    if (state.newOfferGroup.type === 'text') {
                        router.push({
                            name: 'NewOfferText',
                            params: {projectId: data.id, groupId: state.newOfferGroup.id}
                        })
                    } else {
                        router.push({
                            name: 'NewOfferCategory',
                            params: {projectId: data.id, groupId: state.newOfferGroup.id}
                        })
                    }
                }

                if (payload.status === 'edit') {
                    commit('setProjectData', data)

                    let successMessage = [i18n.t('project.edit.notification')]
                    commit('setNotification', successMessage)
                }

                return data
            })
    },

    COPY_PROJECT({state, dispatch, commit}, payload) {
        return axios.post(`projects/${payload.id}/copy`, payload.data, config.AXIOS_DEFAULTS)
            .then(data => {
                let successMessage = [
                    i18n.t('project.duplicate.notification.first_row'),
                    i18n.t('project.duplicate.notification.second_row', {project_number: data.projectNumber})
                ]

                commit('setNotification', successMessage)

                return data
            })
    },

    SEND_ORDER({state, store, commit}, payload) {
        let message = {}, url, method

        commit('updateOrderDataTabs')

        message.discountType = state.discounts.discountType
        message.discounts = state.discounts.groupDiscounts
        message.tabs = getProductsWithValueByTabs(state.orderDataTabs)
        message.checkGuarantee = payload.checkGuarantee ?? false

        if (payload.orderId) {
            url = `orders/${payload.orderId}`
            method = 'patch'
        } else {
            url = `orders/create`
            method = 'post'
        }

        return axios[method](url, message, config.AXIOS_DEFAULTS)
            .then(data => {
                if (payload.checkGuarantee && data.showGuaranteeAlert && data.showGuaranteeAlert == true) {
                    return data;
                }

                if (payload.nextStep) {
                    if (!payload.orderId) {
                        router.replace({name: 'OrderDetails', params: {orderId: data.id}})
                    } else {
                        router.replace({name: 'OrderDetails', params: {orderId: data.order.id}})
                    }
                } else if (!payload.orderId) {
                    router.replace({name: 'Order', params: {orderId: data.id, checkGuarantee: true}})
                } else {
                    loadOrder(commit, data, payload.selectedOrderDataTab)
                }

                let successMessage = [i18n.t('order.page.edit.notification.saved')]
                commit('setNotification', successMessage)
            })
    },

    APPROVE_ORDER({commit}, payload) {
        return axios.post(`orders/${payload.id}/approve`, {}, config.AXIOS_DEFAULTS)
            .then(() => {
                let successMessage = [i18n.t('order.page.status.notification.approved')]
                commit('setNotification', successMessage)
            })
    },

    ANSWER_QUESTION({commit}, payload) {
        return axios.post(`offers/${payload}/answer-question`, {}, config.AXIOS_DEFAULTS)
            .then(() => {
                let successMessage = ['Ajánlat megválaszolva']
                commit('setNotification', successMessage)
            })
    },

    FETCH_STALLED_OFFERS({commit}) {
        return axios.get('offers/fetch-stalled-offers', config.AXIOS_DEFAULTS)
            .then((data) => {
                commit('setStalledOffers', data)
            })
    },

    SAVE_STALLED_OFFERS({commit}, payload) {
        const data = payload.map((offer) => {
            return {
                id: offer.id,
                status: offer.status,
                has_question: offer.has_question ?? null
            };
        });

        return axios.post('offers/save-stalled-offers', { data: data }, config.AXIOS_DEFAULTS).then(() => {
            let successMessage = ['Ajánlatok állapota mentve']
            commit('setNotification', successMessage)
        })
    },

    REJECT_ORDER({commit}, payload) {
        return axios.post(`orders/${payload.id}/reject`, {}, config.AXIOS_DEFAULTS)
            .then(() => {
                let successMessage = [i18n.t('order.page.status.notification.rejected')]
                commit('setNotification', successMessage)
            })
    },

    SEND_ORDER_DETAILS({state, commit}, {id, type, data}) {
        let apiUrl = `orders/${id}/details`
        let method = 'patch'

        if (type === 'offer') {
            apiUrl = `orders/details-by-offer/${id}`
            method = 'post'
        }

        return axios[method](apiUrl, data, config.AXIOS_DEFAULTS)
            .then(data => {
                let successMessage = [i18n.t('order.page.details.notification.save.success')]
                commit('setNotification', successMessage)
                return data
            }).catch(response => {
                if(response.errors) {
                    commit('setValidation', response.errors)
                } else {
                    commit('setNotification', [i18n.t('order.page.details.notification.save.failed')])
                }
            })
    },

    FINISH_ORDER({state, commit}, {id, type, data}) {
        let apiUrl = `orders/${id}/order`

        if (type === 'offer') {
            apiUrl = `orders/details-by-offer/${id}/order`
        }

        return axios.post(apiUrl, data, config.AXIOS_DEFAULTS)
            .then(data => {
                let successMessage = [i18n.t('order.page.details.notification.finish_order.success')]
                commit('setNotification', successMessage)
                return data
            })
    },

    SEND_OFFER({state, store, commit}, payload) {
        let advisoryUserId = null, url, method;

        if (state.offerInformation.advisoryUser) {
            advisoryUserId = state.offerInformation.advisoryUser.id
        }

        let message = {
            forceCopy: payload.forceCopy,
            editorUserId: state.offerInformation.editorUser.id,
            name: state.offerInformation.name,
            validTo: state.offerInformation.validTo,
            advisoryUserId: advisoryUserId,
            observerUserIds: getSelectedIds(state.offerInformation.observerUsers),
        }

        if (state.offerInformation.type === 'text') {
            message.productText = JSON.stringify(state.productText || {})
            message.debugData = state.productTextDebug
        } else {
            message.comments = getPlainCommentsArray(state.additionalDetails.comments)
            message.systemWarrantee = state.additionalDetails.systemWarrantee
            message.variantId = state.orderData.selectedVariant ? state.orderData.selectedVariant.id : null
            message.discountType = state.discounts.discountType
            message.discounts = state.discounts.groupDiscounts
            message.dealerMargin = state.discounts.dealerMargin
            message.dealerMarginAmount = state.discounts.dealerMarginAmount
            message.purchaseMargin = state.discounts.purchaseMargin
            message.purchaseMarginAmount = state.discounts.purchaseMarginAmount
            message.calculation = state.orderData.calculation
            message.products = getProductsWithValue(state.orderData.categories)
            message.pallets = state.pallets
            message.services = state.services
            message.customPallets = state.customPallets
            message.customDemolitionPallets = state.customDemolitionPallets
            message.customTransport = state.customTransport
            message.customDealerTransport = state.customDealerTransport
            message.dealerTransport = state.dealerTransport
            message.transportId = state.selectedTransport
            message.warehouse = state.warehouse
        }

        if (payload.relatedOfferId) {
            url = `offers/create-related/${payload.relatedOfferId}/${payload.groupId}`
            method = 'post'
        } else if (payload.offerId) {
            url = `offers/${payload.offerId}`
            method = 'patch'
        } else {
            url = `offers/create/${payload.projectId}/${payload.groupId}`
            method = 'post'
        }

        return axios[method](url, message, config.AXIOS_DEFAULTS)
            .then(data => {
                if (!data.permissions.canView) {
                    commit('setNotification', [i18n.t('offer.page.edit.notification.no_longer_observed')])
                    router.push({name: 'OfferList'})
                } else {
                    commit('setNotification', [i18n.t('offer.page.edit.notification.saved')])

                    if (payload.offerId && Number(payload.offerId) === Number(data.offer.id)) {
                        loadOffer(commit, data)
                    } else if (!payload.offerId) {
                        if (data.offer.type === 'text') {
                            router.replace({name: 'OfferText', params: {offerId: data.offer.id}})
                        } else {
                            router.replace({name: 'OfferCategory', params: {offerId: data.offer.id}})
                        }
                    } else {
                        if (data.offer.type === 'text') {
                            router.push({name: 'OfferText', params: {offerId: data.offer.id}})
                        } else {
                            router.push({name: 'OfferCategory', params: {offerId: data.offer.id}})
                        }
                    }
                }
                return data
            })
    },

    CHECK_OFFER_FOR_GUARANTEES({state, store, commit}, payload) {
        if (payload.offerId) {
            let url = `offers/check-guarantee/${payload.offerId}`;
            let method = 'get';

            return axios[method](url, config.AXIOS_DEFAULTS)
                .then(data => {
                    return data
                })
        }
    },

    CHECK_ORDER_FOR_GUARANTEES({state, store, commit}, payload) {
        if (payload.orderId) {
            let url = `orders/check-guarantee/${payload.orderId}`;
            let method = 'get';

            return axios[method](url, config.AXIOS_DEFAULTS)
                .then(data => {
                    return data
                })
        }
    },

    SEND_BUG_REPORT({commit}, payload) {
        return axios.post('bug-reports', payload, config.AXIOS_DEFAULTS)
    },

    SAVE_SAVED_COMMENTS({state, commit}) {
        let personalComments = {
            personal: state.savedComments.personal
        }

        return axios.put('comments', personalComments, config.AXIOS_DEFAULTS)
            .then(data => {
                commit('setSavedComments', data)
                let successMessage = [i18n.t('offer.edit_comment.notification')]
                commit('setNotification', successMessage)
            })
    },

    ADD_CATEGORY({state, commit}, groupId) {
        let message = {
            groupId: groupId,
            currentGroupIds: state.orderDataTabs.map(t => t.groupId),
            discountType: state.discounts.discountType,
            discounts: state.discounts.groupDiscounts
        }

        return axios.post('orders/tab/create', message, config.AXIOS_DEFAULTS)
            .then(data => {
                commit('updateOrderDataTabs')
                commit('addToOrderDataTabs', data.tab)
                commit('setDiscounts', data.discounts)
                loadTab(commit, data.tab, false)
                return true
            }).catch((err) => {
                return Promise.reject(err)
            })
    },

    COPY_OFFER({state, commit, dispatch}, payload) {
        return axios.post(`offers/${payload.id}/copy`, {projectId: payload.projectId}, config.AXIOS_DEFAULTS)
            .then(data => {
                if (payload.inList) {
                    dispatch('FETCH_OFFER_PAGES')
                }

                let successMessage = [
                    i18n.t('offer.page.list.notifications.copy.first_row'),
                    i18n.t('offer.page.list.notifications.copy.second_row', {offer_number: data.offerNumber})
                ]

                commit('setNotification', successMessage)
            })
    },

    DELETE_OFFER({state, commit, dispatch}, payload) {
        return axios.delete(`offers/${payload.id}/${payload.withRelated ? 1 : 0}`, config.AXIOS_DEFAULTS)
            .then(() => {
                if (payload.inList) {
                    dispatch('FETCH_OFFERS', {page: payload.page, keepOtherPages: true})
                }

                let successMessage = [i18n.t('offer.page.edit.notification.deleted')]
                commit('setNotification', successMessage)

                if (payload.targetOfferId) {
                    router.push({name: 'OfferCategory', params: {offerId: payload.targetOfferId}})
                } else if (!payload.inList) {
                    router.push({name: 'OfferList'})
                }
            })
    },

    DELETE_ORDER({state, commit, dispatch}, payload) {
        return axios.delete(`orders/${payload.id}`, config.AXIOS_DEFAULTS)
            .then(() => {
                if (payload.inList) {
                    dispatch('FETCH_ORDERS', {page: payload.page, keepOtherPages: true})
                }

                let successMessage = [i18n.t('order.page.list.notification.deleted')]
                commit('setNotification', successMessage)
            })
    },

    DELETE_OBJECT({state, commit, dispatch}, objectId) {
        return axios.delete(`objects/${objectId}`, config.AXIOS_DEFAULTS)
            .then(() => {
                let successMessage = [i18n.t('object.page.list.notification.deleted')]
                commit('setNotification', successMessage)
            })
    },

    GENERATE_OFFER_PDF({state}, payload) {
        return axios.post(`offers/${payload.offerId}/pdf`, payload.message, config.AXIOS_DEFAULTS)
    },

    GENERATE_OFFER_SUMMARY_PDF({state}, payload) {
        return axios.post(`projects/${payload.projectId}/pdf`, payload.message, config.AXIOS_DEFAULTS)
    },

    GENERATE_OBJECT_PDF({state}, payload) {
        return axios.post(`objects/${payload.objectId}/pdf`, payload.message, config.AXIOS_DEFAULTS)
    }
}
